import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Post, Props as PostProps } from './Post'

export interface Props {
  articles?: PostProps[]
  description?: string
}

export const BlogArticles = memo(function BlogArticles({
  articles,
  description,
}: Props) {
  if (!articles) {
    return null
  }

  return (
    <Container>
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      <Posts row wrap space="between">
        {articles
          .sort((a: any, b: any) => (a.created < b.created ? 1 : -1))
          .map((item, index) => (
            <Post key={index} {...item} />
          ))}
      </Posts>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 6rem 10vw;

  @media (max-width: 1199px) {
    padding: 3.75rem 8.454vw;
  }
`

const Description = styled.div`
  max-width: 780px;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2rem;
  line-height: 2.375rem;
  margin: 0 auto 3.75rem;
  text-align: center;

  @media (max-width: 1023px) {
    margin-bottom: 2.25rem;

    br {
      content: ' ';
      display: inline;
      padding: 0 3px;
    }
  }
`

const Posts = styled(FlexBox)``
